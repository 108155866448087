<template>
  <HeaderDialog
    v-if="showHideDialog"
    id="add_users_calendars"
    :color="primaryColor"
    :title="$t('common.addCalendar')"
    :show="showHideDialog"
    :persistent="false"
    :loading="loading"
    width="700"
    @close="showHideDialog = false"
    @action="InsertUsersCalendars"
    :disabledAction="invalid"
    :closeText="$t('action.cancel')"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
  >
    <template v-slot:body>
      <div v-if="disabled">
        <v-card-text class="pb-0">
          <span v-if="isAdmin">
            {{ $t("common.requestFunctionalityScopes") }}
            <div class="d-flex justify-center mt-2">
              <v-btn
                max-width="200"
                :block="false"
                color="accent"
                class="text-none font-weight-medium text-body-2 white--text"
                elevation="0"
                :href="MARKETPLACE_APP_URL"
                target="_blank"
                :loading="loading"
              >
                Aceitar escopos
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </span>
          <span v-else>
            {{ $t("common.requestFunctionalityScopesToNonAdminUser") }}
          </span>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-text>
          {{ $t("common.selectCalendars") }}
        </v-card-text>
        <v-card-text v-html="$t('common.addCalendarDescription')" />
        <v-card-text class="d-flex align-baseline pt-0 flex-wrap">
          {{ $t("common.addCalendarsHelpText") }}:
          <v-btn
            text
            :color="secondaryColor"
            class="d-inline px-1 mx-1 text-none ml-3"
            :disabled="loading"
            :loading="loading"
            @click="getCalendars"
            outlined
          >
            {{ $t("common.searchCalendars") }}
          </v-btn>
        </v-card-text>
        <v-autocomplete
          v-model="calendars"
          :items="allCalendars"
          multiple
          outlined
          item-text="summary"
          item-value="id"
          hide-details
          clearable
          :disabled="loading || disabled"
          :loading="loading"
          class="px-4"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              color="lighten-3"
              :input-value="selected"
              pill
              class="ma-1 link"
              placeholder="Nenhuma agenda selecionada"
            >
              <span class="pr-2">
                {{ item.summary }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>

          <template v-slot:item="{ item, on, attrs }">
            <v-list-item
              v-on="on"
              v-bind="attrs"
              :active-class="lightColors[productName]"
              :class="`mb-1`"
              style="width: inherit"
            >
              <v-list-item-icon>
                <v-icon
                  :color="isSelected(item.id) ? `${primaryColor} darken-2` : ''"
                  v-text="
                    isSelected(item.id)
                      ? 'mdi-checkbox-marked'
                      : 'mdi-checkbox-blank-outline'
                  "
                />
              </v-list-item-icon>
              <v-list-item-content style="max-width: 500px">
                <v-list-item-title
                  class="text-left font-weight-medium"
                  style="max-width: 500px"
                  >{{ item.summary }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-left"
                  style="max-width: 500px"
                >
                  <div style="max-width: 500px; white-space: normal">
                    {{ item.description }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </template>
  </HeaderDialog>
</template>
<script>
import { lightColors } from "@/helpers/variables/colors";
import { mapActions, mapGetters } from "vuex";

import {
  INCREMENTAL_SCOPES,
  MARKETPLACE_APP_URL,
} from "@/helpers/variables/scopes";

export default {
  name: "InsertUsersCalendars",

  props: {
    show: { type: Boolean, required: true },
    users: { type: Array, required: true },
  },

  data() {
    return {
      calendars: null,
      lightColors,
      loagingScopes: false,
      disabled: true,
      MARKETPLACE_APP_URL,
    };
  },

  computed: {
    ...mapGetters([
      "calendarsLoading",
      "allCalendars",
      "secondaryColor",
      "primaryColor",
      "productName",
      "isAdmin",
      "scopeCode",
    ]),

    loading() {
      return this.loagingScopes || this.calendarsLoading;
    },

    invalid() {
      return this.loading || this.disabled || !this.calendars?.length;
    },

    showHideDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },

  watch: {
    show() {
      if (this.show) {
        this.calendars = null;
      }
    },
  },

  methods: {
    ...mapActions(["checkScopes", "getCalendars", "insertCalendars"]),

    isSelected(id) {
      return (this.calendars || []).find((item) => item === id);
    },

    async InsertUsersCalendars() {
      const payload = {
        users: this.users.map((item) => item.email),
        calendars: this.calendars,
      };
      await this.insertCalendars(payload)
        .then(() => this.$emit("getUserCalendars"))
        .finally(() => {
          this.$emit("close");
          this.$emit("saveLog");
        });
    },
  },
  async beforeMount() {
    const calendarScope = INCREMENTAL_SCOPES.CALENDAR;
    this.loagingScopes = true;

    if (!this.scopeCode(calendarScope)) {
      const payload = {
        scopes: [calendarScope],
      };

      await this.checkScopes(payload)
        .then(() => {
          this.disabled = false;
          if (this.allCalendars.length == 0) {
            this.getCalendars();
          }
        })
        .catch(() => {
          this.disabled = true;
        })
        .finally(() => {
          this.loagingScopes = false;
        });
    }
  },
};
</script>
